import request from '../Axios'

// 列表
export const rolePage = data => {
  return request({
    url: '/role/page',
    method: 'get',
    params: data
  })
}

// 列表
export const rolePageSimple = () => {
  return request({
    url: '/role/list-all-simple',
    method: 'get'
  })
}

// 详情
export const roleGet = params => {
  return request({
    url: `/role/get?id=${params.id}`,
    method: 'get'
  })
}

// 获得角色拥有的菜单编号
export const roleResources = params => {
  return request({
    url: `/permission/list-role-resources?roleId=${params.id}`,
    method: 'get'
  })
}



// 删除角色
export const roleDelete = params => {
  return request({
    url: `/role/delete?id=${params.id}`,
    method: 'delete'
  })
}

// 修改角色状态
export const roleUpdateStatus = data => {
  return request({
    url: `/role/update-status`,
    method: 'put',
    data
  })
}


// 修改角色
export const roleUpdate = data => {
  return request({
    url: `/role/update`,
    method: 'put',
    data
  })
}

// 创建角色
export const roleCreate = data => {
  return request({
    url: '/role/create',
    method: 'post',
    data
  })
}


// 赋予角色菜单
export const roleMenu = data => {
  return request({
    url: '/permission/assign-role-menu',
    method: 'post',
    data
  })
}
