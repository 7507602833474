import request from '../Axios'

// 列表
export const adminPage = data => {
  return request({
    url: '/user/admin/page',
    method: 'get',
    params: data
  })
}


// 详情
export const adminGet = data => {
  return request({
    url: `/user/admin/get`,
    method: 'get',
    params: data
  })
}

// 删除角色
export const adminDelete = data => {
  return request({
    url: `/user/admin/delete`,
    method: 'delete',
    params: data
  })
}



// 修改角色
export const adminUpdate = data => {
  return request({
    url: `/user/admin/update`,
    method: 'put',
    data
  })
}

// 启用/禁用
export const adminUpdateStatus = data => {
  return request({
    url: `/user/admin/update-status`,
    method: 'put',
    data
  })
}

// 创建角色
export const adminCreate = data => {
  return request({
    url: '/user/admin/create',
    method: 'post',
    data
  })
}


// 修改管理员密码
export const adminUpdatePassword = data => {
  return request({
    url: `/user/admin/update-password`,
    method: 'put',
    data
  })
}
