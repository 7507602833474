<template>
  <div class="admin">
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="handleChangeAdd">新增</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #isDisable>
        <el-table-column label="状态" align="center">
          <template #default="scoped">
            <el-switch
              v-model="scoped.row.isDisable"
              @change="handleUpdateStatus(scoped.row)"
            />
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="380" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="handleChangeEdit(scoped.row)"
            >
              编辑
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleChangeDelete(scoped.row)"
              v-if="scoped.row.username != 'admin'"
            >
              删除
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleChangeUpdatepsd(scoped.row)"
              v-if="username == 'admin'"
            >
              修改密码
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="adminvisible"
      :title="adminTitle"
      width="500"
      :before-close="adminClose"
    >
      <el-form
        ref="adminFormRef"
        :model="adminForm"
        :rules="adminFormRules"
        label-width="140px"
        class="adminform"
      >
        <el-form-item label="账号：" prop="username">
          <el-input
            v-model="adminForm.username"
            placeholder="请输入"
            :maxLength="10"
            clearable
          />
        </el-form-item>
        <el-form-item label="名称：" prop="nickname">
          <el-input
            v-model="adminForm.nickname"
            placeholder="请输入"
            :maxLength="10"
            clearable
          />
        </el-form-item>
        <el-form-item label="角色：" prop="role">
          <el-select v-model="adminForm.role" multiple placeholder="请选择">
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="密码："
          prop="pwd"
          v-if="adminTitle == '新增管理员'"
        >
          <el-input
            v-model="adminForm.pwd"
            type="password"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item
          label="确认密码："
          prop="pwdok"
          v-if="adminTitle == '新增管理员'"
        >
          <el-input
            v-model="adminForm.pwdok"
            type="password"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="管理员状态：">
          <el-switch v-model="adminForm.state" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="adminSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="updatevisible"
      title="修改密码"
      width="500"
      :before-close="updateClose"
    >
      <el-form
        ref="updateFormRef"
        :model="updateForm"
        :rules="updateFormRules"
        label-width="110px"
        class="updateform"
      >
        <el-form-item label="账号：">
          <el-input
            v-model="updateForm.username"
            placeholder="请输入"
            :maxLength="10"
            clearable
            disabled
          />
        </el-form-item>
        <el-form-item label="密码：" prop="pwd">
          <el-input
            v-model="updateForm.pwd"
            type="password"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="确认密码：" prop="pwdok">
          <el-input
            v-model="updateForm.pwdok"
            type="password"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  adminPage,
  adminDelete,
  adminUpdate,
  adminCreate,
  adminUpdateStatus,
  adminGet,
  adminUpdatePassword
} from '@/api/permissionManagement/admin'
import { rolePageSimple } from '@/api/permissionManagement/role'
const adminFormRef = ref()
const updateFormRef = ref()
const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入确认密码'))
  } else if (value !== adminForm.value.pwd) {
    callback(new Error('两次密码输入不一致'))
  } else {
    callback()
  }
}
const validatePass1 = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入确认密码'))
  } else if (value !== updateForm.value.pwd) {
    callback(new Error('两次密码输入不一致'))
  } else {
    callback()
  }
}
const initData = reactive({
  formLabels: [
    {
      label: 'username',
      title: '管理员账号',
      placeholder: '请输入'
    },
    {
      title: '管理员名称',
      label: 'nickname',
      placeholder: '请输入'
    },
    {
      title: '管理员角色',
      label: 'roleId',
      type: 'options',
      options: [],
      placeholder: '请选择'
    }
  ],
  fromData: {
    // 表单数据
    username: '',
    nickname: '',
    roleId: ''
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    { prop: 'username', label: '账号' },
    { prop: 'nickname', label: '名称' },
    { prop: 'roles', label: '角色' },
    { prop: 'createTime', label: '创建时间' },
    { prop: 'lastLoginTime', label: '最近登录时间' },
    { slot: 'isDisable' },
    { slot: 'action' }
  ],
  adminvisible: false,
  adminTitle: '',
  adminForm: {
    id: '',
    username: '',
    nickname: '',
    role: [],
    pwd: '',
    pwdok: '',
    state: true
  },
  adminFormRules: {
    username: [
      {
        required: true,
        message: '请输入账号',
        trigger: 'blur'
      }
    ],
    nickname: [
      {
        required: true,
        message: '请输入名称',
        trigger: 'blur'
      }
    ],
    role: [
      {
        required: true,
        message: '请选择角色',
        trigger: 'change'
      }
    ],
    pwd: [
      {
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      }
    ],
    pwdok: [{ required: true, validator: validatePass, trigger: 'blur' }]
  },
  roleList: [],
  username: '',
  updatevisible: false,
  updateForm: {
    id: '',
    username: '',
    pwd: '',
    pwdok: ''
  },
  updateFormRules: {
    pwd: [
      {
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      }
    ],
    pwdok: [{ required: true, validator: validatePass1, trigger: 'blur' }]
  }
})

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  adminvisible,
  adminTitle,
  adminForm,
  roleList,
  adminFormRules,
  username,
  updatevisible,
  updateForm,
  updateFormRules
} = toRefs(initData)

onMounted(() => {
  getDataList()
  getRolePageSimple()
  if (localStorage.getItem('username')) {
    username.value = localStorage.getItem('username')
  }
})
const getRolePageSimple = () => {
  // const data = {
  //   current: 1,
  //   size: 9999
  // }
  rolePageSimple().then(({ data: res }) => {
    if (res.code == 200) {
      roleList.value = res.data
      formLabels.value[2].options = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 表单提交
const adminSubmit = () => {
  if (!adminFormRef.value) return
  adminFormRef.value.validate(valid => {
    if (valid) {
      const data = {
        username: adminForm.value.username,
        nickname: adminForm.value.nickname,
        roleIds: adminForm.value.role,
        isDisable: adminForm.value.state
      }
      if (adminForm.value.id == '') {
        data.password = adminForm.value.pwd
        adminCreate(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            adminClose()
            getDataList()
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else {
        data.id = adminForm.value.id
        adminUpdate(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            adminClose()
            getDataList()
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
    } else {
      console.log('error submit!')
      return false
    }
  })
}

// 修改密码提交
const updateSubmit = () => {
  if (!updateFormRef.value) return
  updateFormRef.value.validate(valid => {
    if (valid) {
      const data = {
        id: updateForm.value.id,
        password: updateForm.value.pwd
      }
      adminUpdatePassword(data).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          updateClose()
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else {
      console.log('error submit!')
      return false
    }
  })
}

// 修改密码
const handleChangeUpdatepsd = row => {
  updateForm.value.id = row.id
  updateForm.value.username = row.username
  updatevisible.value = true
}

// 关闭修改密码弹窗
const updateClose = () => {
  updateFormRef.value.resetFields()
  updatevisible.value = false
}

// 关闭
const adminClose = () => {
  adminFormRef.value.resetFields()
  adminvisible.value = false
}

// 新增
const handleChangeAdd = () => {
  adminTitle.value = '新增管理员'
  adminvisible.value = true
}
// 编辑
const handleChangeEdit = row => {
  adminTitle.value = '编辑管理员'
  adminGetList(row.id)
  adminvisible.value = true
}

// 管理员信息
const adminGetList = id => {
  const data = {
    id: id
  }
  adminGet(data).then(({ data: res }) => {
    if (res.code == 200) {
      adminForm.value.id = res.data.id
      adminForm.value.username = res.data.username
      adminForm.value.nickname = res.data.nickname
      adminForm.value.role = res.data.roleIds
      adminForm.value.state = res.data.isDisable
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 启用/禁用
const handleUpdateStatus = row => {
  const data = {
    id: row.id,
    isDisable: row.isDisable
  }
  adminUpdateStatus(data).then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      getDataList()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 删除
const handleChangeDelete = row => {
  ElMessageBox.confirm('删除后该账号无法恢复，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      adminDelete({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    username: '',
    nickname: '',
    roleId: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.username !== '') {
    data.username = fromData.value.username
  }
  if (fromData.value.roleId !== '') {
    data.roleId = fromData.value.roleId
  }
  if (fromData.value.nickname !== '') {
    data.nickname = fromData.value.nickname
  }
  adminPage(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}
</script>
<style lang="scss" scoped>
.adminform {
  .el-input,
  .el-select {
    width: 200px;
  }
}
</style>
